@import 'fonts/fonts'
@import 'variables.sass'

*
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif

  &::-webkit-scrollbar
    background-color: transparent
    cursor: pointer
    width: 7px
    height: 7px

  &::-webkit-scrollbar-button
    display: none

  &::-webkit-scrollbar-thumb
    background-color: #C8C5C54D
    border-radius: 4px

a
  color: inherit

input::-webkit-contacts-auto-fill-button
  visibility: hidden
  display: none !important
  pointer-events: none
  position: absolute
  right: 0

h1
  font-size: 18px
  vertical-align: text-bottom
  font-weight: 800
  color: var(--secundary)
  margin: 0

h2
  font-size: 18px
  font-weight: 500
  color: var(--secundary)
  margin: 0

h3
  font-size: 14px
  font-weight: 500
  color: var(--secundary)
  margin: 0

h4
  font-size: 12px
  font-weight: 500
  color: var(--secundary)
  margin: 0

p
  margin: 0

.go-healt-form.go-healt-form
  padding: 0
  text-decoration: underline
  color: inherit
  font-size: inherit

#map
  width: 100%
  height: 100%

.PrivatePickersToolbar-dateTitleContainer .MuiButtonBase-root.MuiIconButton-root,
.PrivateDateRangePickerToolbar-penIcon.PrivateDateRangePickerToolbar-penIcon
  display: none

.MuiFilledInput-root.MuiFilledInput-root
  // background: var(--tertiary) !important #tenemos que definir que hacemos con esta rule.
  background-color: $parso-light-blue-light-gray !important
  border-radius: 5px
  padding: 0

.MuiFilledInput-root.MuiFilledInput-underline::before
  border-bottom: 0px !important

.MuiPopover-paper.MuiPopover-paper
  box-shadow: 0px 0px 6px rgb(146 146 146 / 50%)
  border-radius: 6px

.no-select
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.cp-material-bar::-webkit-scrollbar
  background-color: transparent
  cursor: pointer
  width: 7px

.cp-material-bar::-webkit-scrollbar-button
  display: none

.cp-material-bar::-webkit-scrollbar-thumb
  background-color: #C8C5C54D
  border-radius: 4px

.cp
  cursor: pointer

.m0
  margin: 0

.mr-6
  margin-right: 6px

.mb-6
  margin-bottom: 6px

.mb-15
  margin-bottom: 15px

.ml-15
  margin-left: 15px

.mt-10
  margin-top: 10px

.mb-10
  margin-bottom: 10px

.ml-auto
  margin-left: auto !important

.ml-10
  margin-left: 10px

.mr-10
  margin-right: 10px

.mt-30
  margin-top: 30px

.mb-30
  margin-bottom: 30px

.mt-60
  margin-top: 60px

.mr-15
  margin-right: 15px

.p0
  padding: 0

.pl-15
  padding-left: 15px

.pr-15
  padding-right: 15px

.pa
  position: absolute

.pr
  position: relative

.pb-0
  padding-bottom: 0

.text-right
  text-align: right

.text-left
  text-align: left

.text-end
  text-align: end

.vl-base td
  vertical-align: baseline

.w100
  width: 100%

.d-bl
  display: block

.d-ib
  display: inline-block

.d-i
  display: inline

.fr
  float: right

.fl
  float: left

.cp-stack
  height: 32px

p.info
  color: $parso-green-blue
  font-size: 13px
  display: flex
  align-items: center
  margin-top: 13px
  font-weight: lighter
  svg
    margin-right: 8px

.cp-tabs
  margin: 15px 0 21px 40px
  min-height: 25px !important
  .cp-tab
    padding: 0
    margin-right: 40px
    align-items: start
    min-height: 25px
    min-width: auto
    font-size: 13px
    font-weight: 500
    padding-bottom: 7px

.cp-card.MuiCard-root
  min-width: 340px
  min-height: 186px
  width: calc(100% - 6px)
  box-shadow: 0px 0px 3px $parso-shadow-color
  border-radius: 5px
  cursor: pointer

.cp-button.cp-button
  box-shadow: none
  font-weight: 500
  font-size: 16px
  line-height: 16px
  padding-top: 10px
  padding-bottom: 10px
  height: 36px
  &.Mui-disabled
    background-color: $parso-disabled-gray !important

.cp-icon-menu.MuiButtonBase-root
  padding: 0
  margin-right: 17px
  vertical-align: sub

.arrow-back
  transform: rotate(180deg)
.MuiButtonBase-root.close-button
  position: absolute
  top: 16px
  right: 16px
  color: #4A4A68
  z-index: 10
  background: #ffffffab
.detail-list
  color: $parso-high-gray
  font-size: 14px
  list-style: none
  margin: 0
  padding: 0
  margin-bottom: 100px
  overflow-y: scroll
  li
    margin-bottom: 20px
    &.time-wrapper
      margin-bottom: 10px

      & > span
        position: relative
        top: -7px

    & > svg
      vertical-align: middle
      margin-right: 5px

    & > span
      margin-left: 10px
      color: $parso-denied

.detail-list-workplace
  display: flex
  flex-direction: column
  column-gap: 28px

  .detail-list
    display: inline-block
    vertical-align: top
    width: 350px
    margin-bottom: 20px
    overflow: visible
  .detail-list-map
    height: calc(70vh - 335px)
    display: inline-block
    background: $parso-gray
    width: 100%
    min-height: 300px
    max-height: 400px
    .leaflet-container
      height: 100%

.denied-reason
  font-size: 14px
  color: $parso-black-ligth-text
  .denied-reason-detail
    background: $parso-disabled-gray
    color: black
    padding: 10px
    transform: translateX(-10px)
    border-radius: 5px
    margin: 8px 0 15px
    min-height: 40px
    width: calc( 100% - 10px )
    line-height: 19px

.time-picker-button
  margin: 0
  background: #ECF1F4
  width: 125px
  border: 0
  border-radius: 3px
  vertical-align: middle

  input, button, textarea
    font-size: 12px

  button
    padding-right: 5px
    svg
      width: 14px

  input, textarea
    padding: 6px 5px
    color: $parso-high-gray

.select-button
  background: #ECF1F4
  & > div
    padding: 6px 5px
    font-size: 12px
    color: $parso-high-gray

.form-input-group > .MuiDivider-root
  display: none

.center-message
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  margin: auto
  display: block
  width: 204px
  height: 23px
  text-align: center

.dialog-detail.detail-map
  .MuiDialogContent-root.dialog-detail-content
    padding: 30px 32px

    .legend
      margin-bottom: 20px
      font-size: 16px
      line-height: 22px
      color: $parso-high-gray-blue
      letter-spacing: 0.015em
      width: 100%

  .detail-map-header
    h1
      color: $parso-semi-black
      font-weight: 500
      font-size: 16px
      line-height: 27px
    h2
      color: rgba(32, 31, 30, 0.58)
      font-size: 15px
      line-height: 27px
      margin-bottom: 15px
  .detail-map-footer
    text-align: right

.image-information-dialog
  .MuiPaper-root
    .MuiDialogContent-root.dialog-info-content
      .detail-header
        font-size: 24px
        font-weight: 500
        line-height: 33px
      img
        width: 321px
        margin: 10px auto 0
        display: block
      p.enabled
        font-size: 16px
        line-height: 22px

.MuiDataGrid-root.MuiDataGrid-root
  border: none
  color: $parso-high-gray
  font-size: 14px
.MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders
  border-bottom: 0
  color: var(--secundary)
  background: var(--tertiary)
  font-weight: 700

.MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell
  border-bottom: none

.MuiDataGrid-iconSeparator.MuiDataGrid-iconSeparator
  display: none

.table-wrapper
  height: calc(100vh - 260px)
  width: 100%

.MuiDataGrid-row .table-button
  transition: opacity 0.4s ease
  opacity: 0

.MuiDataGrid-row:hover .table-button
  opacity: 1

.detail-list.visitor
  .MuiOutlinedInput-input
    padding: 3px 5px
    font-size: 14px

.new-reservation-footer
  text-align: right
  margin-top: 0px

svg
  &.PENDING
    color: $parso-pending
  &.APPROVED
    color: $parso-approved
  &.DENIED
    color: $parso-denied
  &.DANGER
    color: $parso-danger-light
  &.SUCCESS
    color: $parso-success-strong

.detail-map-footer-info
  max-width: 100vw
  h2
    margin-bottom: 24px
    font-size: 18px
    color: $parso-green-blue
    font-weight: 600
  h3
    margin-bottom: 8px
    font-size: 18px
    color: $parso-high-gray
    font-weight: 200
  .amenitie
    font-size: 14px
    color: $parso-high-gray
    border: solid 1px $parso-high-gray
    border-radius: 8px
    padding: 8px 16px
    height: 35px
    margin: 0 5px 8px 0
    display: inline-block
    box-sizing: border-box
    vertical-align: bottom
    label
      line-height: 19px
      vertical-align: bottom

.save-setting-button-wrapper
  box-sizing: border-box
  width: 100%
  position: fixed
  bottom: 0
  left: 0
  background: white
  height: 90px
  padding: 24px 0
  text-align: center
  box-shadow: 0px 0px 6px rgba(146, 146, 146, 0.5)

.MuiAlert-root
  border-left: solid 1px
  border-radius: 0 4px 4px 0

.prevent-select
  -webkit-user-select: none
  -ms-user-select: none
  user-select: none

.subtitle-1
  font-size: 18px
  font-style: normal
  font-weight: 600
  line-height: normal
  text-wrap: nowrap

.caption-text
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal

.amenity-chip
  border-radius: 8px !important
  font-size: 14px !important
  color: $parso-high-gray !important

.attendees-info
  color: $parso-green-blue
  font-size: 14px
  margin: 20px auto 0

.attendee-chip
  border-radius: 10px !important
  border: 0.5px solid #5F75B9 !important
  background: $parso-super-light-blue !important
  color: $parso-black-text !important
  height: 28px !important
  svg
    fill: #6C85D3
    height: 16px
    width: 16px

.hide
  display: none !important

.MuiTabs-root.new-visit-tabs-container
  margin: 10px 0 0 0
  overflow: inherit
  &>div
    overflow: inherit !important
  button
    background: $parso-light-blue-light-gray
    border-radius: 6px 6px 0 0
    height: 36px
    &.Mui-selected
      box-shadow: 0px -3px 6px rgba(146, 146, 146, 0.3)
      background: white
      z-index: 1
  .MuiTabs-flexContainer
    height: 26px
  .MuiTabs-indicator
    z-index: 2
    transform: scaleX(0.8)
    bottom: 8px
    height: 1px
    transition: none

@media screen and (min-width: $lg)

  .save-setting-button-wrapper
    display: inline-block
    padding: 0
    position: fixed
    top: 148px
    right: 65px
    background: none
    text-align: center
    box-shadow: none
    width: auto
    height: auto
    left: auto
    bottom: auto

@media screen and (min-width: $md)

  .detail-list-workplace
    flex-direction: row
    .detail-list
      overflow: scroll

    .detail-list-map
      width: calc(100% - 350px)

  .detail-map-footer-info
    width: 100vw

  h1
    font-size: 24px

  .table-wrapper
    height: calc(100vh - 200px)
    width: 100%

  .cp-tabs
    margin: 15px 0 32px 0
    .cp-tab
      font-size: 18px

  .cp-card.MuiCard-root
    max-width: 350px
    height: 332px
    box-shadow: 0px 0px 6px $parso-shadow-color

  .form-input-group
    // background: var(--tertiary) #tenemos que definir que hacemos con esta rule.
    background-color: $parso-light-blue-light-gray
    border-radius: 8px

    & > .MuiDivider-root
      display: block

  .image-information-dialog
    .MuiPaper-root
      .MuiDialogContent-root.dialog-info-content
        p.enabled
          width: 376px
          margin: 10px auto 29px
        .detail-footer
          width: 217px
          margin-bottom: 20px

  .attendees-info
    width: 375px

.PARSO-FORM
  .MuiInputLabel-root.MuiInputLabel-root,
  .MuiSelect-select.MuiSelect-select,
  .MuiInputBase-input.MuiInputBase-input,
  .MuiAutocomplete-inputRoot.MuiAutocomplete-inputRoot
    font-size: 12px
    line-height: 16px
    padding: 11px 19px
    border-radius: 4px

  .MuiInputLabel-root.MuiInputLabel-root
    top: -20px
    left: -24px
    font-size: 14px
    font-weight: 500
    line-height: 19px
    display: block
