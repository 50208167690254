.ParkingReservation
  .entry-exit-time-form
    margin-bottom: 55px
    &:last-of-type
      margin-bottom: 0
    & > label
      font-size: 14px
      width: 90px
      &::first-letter
        text-transform: uppercase

  .MuiAlert-root
    border-left: solid 1px
    border-radius: 0 4px 4px 0

  .save-button.MuiButton-root.MuiButton-root
    min-width: 316px
    font-family: 'Avenir', sans-serif
    letter-spacing: 1px

.MuiPaper-root.MuiAutocomplete-paper
  width: max-content
  max-width: 90vw

.dialog-parking-location
  .MuiPaper-root.MuiDialog-paper
    border-radius: 10px
    .close-icon
      position: absolute
      top: 8px
      right: 8px
      background-color: #fff
      & > svg
        fill: #6C85D3
